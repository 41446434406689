export enum Routes {
  Home = '/',
  TypingTest = '/typing-test',
  Competitions = '/competitions',
  Competition = '/competition',
  TextPractice = '/text-practice',
  TextPracticeCreate = '/text-practice/create',
  TextPracticeEdit = '/text-practice/edit',
  Multiplayer = '/multiplayer',
  MultiplayerRace = '/multiplayer/race',
  Moderators = '/moderators',
  ModeratorsTypingTestResults = '/moderators/typing-test-results',
  ModeratorsEmails = '/moderators/emails',
  ModeratorsTheming = '/moderators/theming',
  ModeratorsUserDetails = '/moderators/user-details',
  ModeratorsBannedUsernames = '/moderators/banned-usernames',
  CustomTypingTest = '/custom-typing-test',
  Blog = '/blog',
  Contact = '/contact',
  Translate = '/translate',
  About = '/about',
  Faq = '/faq',
  Achievements = '/achievements',
  Press = '/press',
  Login = '/login',
  SignUp = '/signup',
  Partners = '/partners',
  Imprint = '/imprint',
  Privacy = '/privacy',
  Settings = '/settings',
  SettingsProfile = '/settings/profile',
  SettingsAccount = '/settings/account',
  SettingsNotifications = '/settings/notifications',
  // SettingsSupporter = '/settings/supporter',
  Share = '/share',
  Terms = '/terms',
  Gdpr = '/gdpr',
  Cookie = '/cookie',
  ResetPassword = '/password/reset',
  RecoverPassword = '/password/recover',
  User = '/user',
}
