'use client';

import React, { type JSX } from 'react';
import { PropsWithClassName } from '@Components/helper';
import { TextAlign, TextColor, TextSize, TextTransform, TextWeight } from '@Helpers/types/text';
import { Root } from './Text.styles';
interface Props extends PropsWithClassName {
  children: string | React.ReactNode;
  size: TextSize;
  mobileSize?: TextSize;
  bold?: boolean;
  color?: TextColor;
  onClick?: () => void;
  textAlign?: TextAlign;
  textTransform?: TextTransform;
  as?: keyof JSX.IntrinsicElements;
  isTruncated?: boolean;
  inline?: boolean;
}
const Text = (props: Props): React.ReactElement => {
  const {
    children,
    bold,
    size,
    mobileSize,
    dataTestId,
    onClick,
    color,
    textAlign = TextAlign.Initial,
    textTransform = TextTransform.Unset,
    as,
    isTruncated = false,
    inline = false
  } = props;
  return <Root $weight={bold ? TextWeight.SemiBold : TextWeight.Light} $size={size} $mobileSize={mobileSize} as={as} $color={color ?? TextColor.Primary} className={props.className} data-testid={dataTestId ? dataTestId : 'Text-root'} onClick={onClick} $textAlign={textAlign} $textTransform={textTransform} $isTruncated={isTruncated} $inline={inline} data-sentry-element="Root" data-sentry-component="Text" data-sentry-source-file="Text.tsx">
      {children}
    </Root>;
};
export { Text };